import React from 'react';
import styled from 'styled-components';
import {exhibitionLocations, convertToSlug} from "../../utils/helpers";
import Container from "../atoms/Container";
import {useStaticQuery, graphql, Link} from "gatsby"
import Accordion from "../atoms/Accordion";

const Holder = styled.div`
  display: none;
  @media ( ${props => props.theme.breakpoints.md} ) {
    display: block;
    margin-left: calc(35% - 1rem);
    position: relative;
    z-index: 10;
    margin-top: 0.7rem;
  }
  a:hover { color: ${props => props.theme.colours.blue};
`;

const Inner = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  align-items: start;
  justify-content: start;
  max-width: 850px;
  padding: 0 1rem;

  ul {
    list-style: none;
    padding-left: 0;

    &.artists {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 1rem;
    }
  }

  a[aria-current="page"] {
    color: ${props => props.theme.colours.focus};
  }
`;

const datesWithTitles = [
  'Roylston Street (2013-present)',
  'Neild Avenue (2006-2012)',
  'Elizabeth Street (1998-2003)',
  'Lennox Street (1994-1997)'
]

function ExhibitionsFilter() {

  const data = useStaticQuery(graphql`query exhibitionsFilterQuery {
  allAirtableArtists(
    filter: {data: {Represented: {eq: true}, Name: {ne: "DO NOT REMOVE"}}}
    sort: {data: {Name: ASC}}
  ) {
    nodes {
      id
      data {
        Name
        Exhibition {
          data {
            Name
          }
        }
      }
    }
  }
}`)

  return (
    <Holder>
      <Container>
        <Inner>
          <Accordion title="View by location">
            <ul className="dates">
              {exhibitionLocations.map((date, i) => <li key={date}><Link
                to={`/exhibitions/${convertToSlug(date)}`}>{datesWithTitles[i]}</Link></li>)}
            </ul>
          </Accordion>
          <Accordion title="View by artist">
            <ul className="artists">
              {data.allAirtableArtists.nodes.map(node => node.data.Exhibition && <li key={node.id}><Link
                to={`/exhibitions/${convertToSlug(node.data.Name)}`}>{node.data.Name}</Link></li>)}
            </ul>
          </Accordion>
        </Inner>
      </Container>
    </Holder>
  )
}

export default ExhibitionsFilter;