exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-artists-js": () => import("./../../../src/pages/artists.js" /* webpackChunkName: "component---src-pages-artists-js" */),
  "component---src-pages-exhibitions-current-js": () => import("./../../../src/pages/exhibitions/current.js" /* webpackChunkName: "component---src-pages-exhibitions-current-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-stockroom-js": () => import("./../../../src/pages/stockroom.js" /* webpackChunkName: "component---src-pages-stockroom-js" */),
  "component---src-templates-artist-js": () => import("./../../../src/templates/Artist.js" /* webpackChunkName: "component---src-templates-artist-js" */),
  "component---src-templates-exhibition-list-by-artist-page-js": () => import("./../../../src/templates/ExhibitionListByArtistPage.js" /* webpackChunkName: "component---src-templates-exhibition-list-by-artist-page-js" */),
  "component---src-templates-exhibition-list-by-location-page-js": () => import("./../../../src/templates/ExhibitionListByLocationPage.js" /* webpackChunkName: "component---src-templates-exhibition-list-by-location-page-js" */),
  "component---src-templates-exhibition-list-page-js": () => import("./../../../src/templates/ExhibitionListPage.js" /* webpackChunkName: "component---src-templates-exhibition-list-page-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/Page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

