import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";

const Holder = styled.nav`
  display: none;
  @media ( ${props => props.theme.breakpoints.lg} ) {
    position: fixed;
    bottom: calc(2rem - 1px);
    left: calc(6.4rem + 2rem);
    margin: 1rem 3rem;
    display: block;
  }
  @media ( ${props => props.theme.breakpoints.xl} ) {
    left: calc(7.5rem + 2rem);
    margin: 1rem 4rem;
  }
  ul {
    list-style: none;
    margin: 0;
    padding-left: 0;
    padding-bottom: ${props => props.paddingBottom}rem;
    li {
      line-height: 1.5rem;
      ${(props) => props.theme.fluidType(2)};
      @media( ${props => props.theme.breakpoints.lg} ) {
        font-size: 1.2rem;
      }
      @media( ${props => props.theme.breakpoints.xl} ) {
        font-size: 1.4rem;
      }
    }
    a { 
      cursor: pointer;
      text-decoration: none;
      &:hover {
        text-decoration: none;
      }
    }
  }
`;

function SubNavHolder({children, paddingBottom}) {
  return (
    <Holder paddingBottom={paddingBottom}>
      <ul className="serif">
        {children}
      </ul>
    </Holder>
  )
}

SubNavHolder.propTypes = {
  paddingBottom: PropTypes.number.isRequired,
};

SubNavHolder.defaultProps = {
  paddingBottom: 0,
};

export default SubNavHolder;