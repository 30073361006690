export const convertToSlug = (Text) => {
  return (
    Text.toLowerCase()
      .replace(/[^\w ]+/g, "")
      .replace(/ +/g, "-")
  )
}

export const exhibitionLocations = [
  "Roylston Street",
  "Neild Avenue",
  "Elizabeth Street",
  "Lennox Street"
];