import React from 'react';
import {Link} from "gatsby";
import SubNavHolder from "../organisms/SubNavHolder";
import styled from "styled-components";

const Holder = styled.div`
  ul {
    a[aria-current="page"],
    a:hover {
      color: ${props => props.theme.colours.blue};
    }
  }
`;

function ExhibitionsSubNav() {
  return (
    <Holder>
      <SubNavHolder paddingBottom={4.5}>
        <li><Link to="/exhibitions/current">Current</Link></li>
        <li><Link to="/exhibitions">Past</Link></li>
      </SubNavHolder>
    </Holder>
  )
}

export default ExhibitionsSubNav;