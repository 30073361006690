import {Link} from "gatsby";
import React, {useState} from "react";
import Container from "../atoms/Container";
import styled from "styled-components";
import Hamburger from '../../assets/svg/hamburger.inline.svg';
import Logo from '../../assets/svg/logo.inline.svg';
import SmallNavigation from "../organisms/SmallNavigation";

const Holder = styled.header`
  @media( ${props => props.theme.breakpoints.md} ) {
    position: fixed;
    top: 1rem;
    left: 1rem;
  }
  svg {
    height: 4rem;
    width: auto;
    display: block;
    @media( ${props => props.theme.breakpoints.md} ) {
      height: 5rem;
    }
  }
`;

const Inner = styled.header`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 1rem 0 2rem 0;
  p {
    margin: 0;
  }
`;

const ButtonHolder = styled.div`
  @media( ${props => props.theme.breakpoints.md} ) {
    display: none;
  }
`;

function Header() {

  const [navOpen, setNavOpen] = useState(false);

  return (
    <Holder>
      <Container>
        <Inner>
          <p className="small">
            <Link to="/"><Logo/></Link>
          </p>
          <ButtonHolder>
            <button className="icon" onClick={() => setNavOpen(true)} aria-label="Open menu"><Hamburger /></button>
          </ButtonHolder>
        </Inner>
      </Container>
      <SmallNavigation show={navOpen} clickHandler={() => setNavOpen(false)}/>
    </Holder>
  );
}

export default Header;
