import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";
import Navigation from "../molecules/Navigation";
import Cross from "../../assets/svg/cross.inline.svg";

const Holder = styled.div`
  position: fixed;
  top: 0;
  left: 100%;
  bottom: 0;
  background-color: ${props => props.theme.colours.white};
  padding: 1rem;
  width: 100%;
  z-index: 10;
  transform: ${props => props.show ? 'translateX(-100%)' :'none'};
  transition: transform 0.25s ease-in-out;
  @media( ${props => props.theme.breakpoints.md} ) {
    display: none;
  }

  > :first-child { margin-top: 0; }

  > :last-child { margin-bottom: 0; }
`;

const ButtonHolder = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

function SmallNavigation({show, clickHandler}) {
  return (
    <Holder show={show}>
      <ButtonHolder>
        <button className="icon" onClick={() => clickHandler()} aria-label="Open menu"><Cross /></button>
      </ButtonHolder>
      <Navigation clickHandler={clickHandler}/>
    </Holder>
  )
}

SmallNavigation.propTypes = {
  show: PropTypes.bool.isRequired,
  clickHandler: PropTypes.func.isRequired
};

export default SmallNavigation;