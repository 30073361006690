import React from 'react';
import styled from 'styled-components';
import Navigation from "../molecules/Navigation";
import PropTypes from "prop-types";
import classNames from "classnames";

const Holder = styled.div`
  display: none;
  @media( ${props => props.theme.breakpoints.md} ) {
    position: fixed;
    bottom: 2rem;
    left: 1rem;
    margin: 1rem;
    display: block;
  }
  @media( ${props => props.theme.breakpoints.lg} ) {
    width: 6.4rem;
  }
  @media( ${props => props.theme.breakpoints.xl} ) {
    width: 7.5rem;
  }
  &.exhibitionsActive {
    .blue { color: ${props => props.theme.colours.blue}; }
  }
  &.artistsActive {
    .orange { color: ${props => props.theme.colours.orange}; }
  }
`;

function LargeNavigation({exhibitionsActive, artistsActive}) {
  let classes = classNames({
    exhibitionsActive: exhibitionsActive,
    artistsActive: artistsActive
  });
  return (
    <Holder className={classes}>
      <Navigation/>
    </Holder>
  )
}

LargeNavigation.propTypes = {
  exhibitionsActive: PropTypes.bool,
  artistsActive: PropTypes.bool,
};

export default LargeNavigation;