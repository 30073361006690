import React from "react"
import {Link} from "gatsby"
import PropTypes from "prop-types";
import styled from "styled-components";

const Holder = styled.nav`
  ul {
    list-style: none;
    padding-left: 0;
    margin: 0;
    li {
      ${(props) => props.theme.fluidType(4)};
      line-height: 1.2;
      @media( ${props => props.theme.breakpoints.md} ) {
        font-size: 1.2rem;
        line-height: 1.5rem;
      }
      @media( ${props => props.theme.breakpoints.xl} ) {
        font-size: 1.4rem;
      }
    }

    a {
      text-decoration: none;
      &:hover {
        text-decoration: none;
        &.blue { color: ${props => props.theme.colours.blue}; }
        &.orange { color: ${props => props.theme.colours.orange}; }
        &.green { color: ${props => props.theme.colours.green}; }
        &.pink { color: ${props => props.theme.colours.pink}; }
        &.yellow { color: ${props => props.theme.colours.yellow}; }
      }
    }

    a[aria-current="page"],
    a.active {
      &.blue { color: ${props => props.theme.colours.blue}; }
      &.orange { color: ${props => props.theme.colours.orange}; }
      &.green { color: ${props => props.theme.colours.green}; }
      &.pink { color: ${props => props.theme.colours.pink}; }
      &.yellow { color: ${props => props.theme.colours.yellow}; }
    }
    .hide-desktop {
      @media( ${props => props.theme.breakpoints.md} ) {
        display: none;
      }
    }
  }
`;

function Navigation({clickHandler}) {

  const handleClick = () => {
    clickHandler();
  }
  return (
    <Holder>
      <ul className="serif">
        <li><Link className="blue" to="/exhibitions/current" onClick={() => handleClick()} partiallyActive={true}><span className="hide-desktop">Current </span>Exhibitions</Link></li>
        <li><Link className="blue hide-desktop" to="/exhibitions" onClick={() => handleClick()}>Past Exhibitions</Link></li>
        <li><Link className="orange" to="/artists" onClick={() => handleClick()}>Artists</Link></li>
        <li><Link className="green" to="/stockroom" onClick={() => handleClick()}>Browse</Link></li>
        <li><Link className="yellow" to="/about" onClick={() => handleClick()}>About</Link></li>
        <li><Link className="pink" to="/contact" onClick={() => handleClick()}>Contact</Link></li>
      </ul>
    </Holder>
  )
}

Navigation.propTypes = {
  clickHandler: PropTypes.func,
};

Navigation.defaultProps = {
  clickHandler: () => {
    return null
  },
};

export default Navigation
