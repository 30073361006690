import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Arrow from '../../assets/svg/arrow.inline.svg';

const Holder = styled.div`
  margin: 1rem 0;
  button {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0;
    text-transform: none;
    text-align: left;
    cursor: pointer;
    white-space: normal;
    &:hover { 
      color: ${props => props.theme.colours.blue};
      svg path {
        stroke: ${props => props.theme.colours.blue};
      }
    };
    > * { margin: 0; }
    svg {
      width: 0.5rem;
      margin-left: 1rem;
      margin-top: 0.2rem;
      height: auto;
      display: block;
      transform: ${props => props.active ? 'rotate(-90deg)' : 'rotate(90deg)'};
      transition: transform 0.25s;
    }
  }
`;

const Content = styled.div`
  
`;

function Accordion( { title, children } ) {
  const [ active, setActive ] = useState( false );
  return (
    <Holder className="accordion-holder" active={active}>
      <button className="link accordion-title hover-black-text" onClick={() => { setActive( !active ) }}>{title} <Arrow/></button>
      {active && <Content className="accordion-content">{children}</Content>}
    </Holder>
  )
}

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Accordion;
