import React, { Component } from "react";
import PropTypes from "prop-types";
import '../utils/fontface.css';

import Header from "../components/molecules/Header";

import GlobalStyles from "../components/atoms/GlobalStyles";
import styled, { ThemeProvider } from "styled-components";
import { theme } from "../utils/styling";
import LargeNavigation from "../components/organisms/LargeNavigation";
import ExhibitionsSubNav from "../components/atoms/ExhibiitonsSubNav";
import ExhibitionsFilter from "../components/molecules/ExhibitionsFilter";

const Main = styled.main`
  @media( ${props => props.theme.breakpoints.md} ) {
    margin-left: 35%;
    margin-top: 2rem;
    max-width: 850px;
  }
  > :first-child { 
    margin-top: 0; 
    > :first-child { 
      margin-top: 0;
      > :first-child {
        margin-top: 0;
      }
    }
  }
`;

class Index extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <>
          <GlobalStyles/>
          <Header/>
          {this.props.location.pathname !== '/' && <LargeNavigation
            exhibitionsActive={this.props.location.pathname.includes( 'exhibition' )}
            artistsActive={this.props.location.pathname.includes( 'artist/' )}/>}
          {this.props.location.pathname.includes( 'exhibition' ) && <ExhibitionsSubNav/>}
          {this.props.location.pathname.includes( 'exhibitions' ) && !this.props.location.pathname.includes( 'current' ) &&<ExhibitionsFilter/>}
          <Main>{this.props.children}</Main>
        </>
      </ThemeProvider>
    );
  }
}

Index.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Index;
